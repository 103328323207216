<template>
  <Layout>
    <div class="dashboard-all">
      <div class="dataUC">
        <div class="dataUC__header">
          <div class="dataUC__header-left">
            <div class="dataUC__header__pagination" v-if="showMenu">
              <list-pagination
                :listPagesCount="tableRules.lengthMenu"
                :pageSize="tableRules.limit"
                @changePageFromListPagination="changePageFromListPagination"
              >
              </list-pagination>
            </div>
            <div class="dataUC__header__showCol">
              <list-columns-name
                :listColumns="dataTable.columnsOptionHide"
                :listColHide="listColHide"
                :countCloseListColumnsName="countCloseListColumnsName"
                @showColumn="showColumn"
                @hideColumn="hideColumn"
                @closeListFilter="closeListFilter"
                @closeWarningData="closeWarningData"
              ></list-columns-name>
            </div>
          </div>
          <div class="dataUC__header-mid">
            <div class="dataUC__header__filter">
              <list-columns-filter
                :listColumns="dataTable.selectColumnsToFilter"
                :countCloseListFilter="countCloseListFilter"
                :nameFilterURL="nameFilterURL"
                @nameColumnToFilter="nameColumnToFilterPlacehoder"
                @closeListColumnsName="closeListColumnsName"
                @closeWarningData="closeWarningData"
                :key="keyListColumnsFilter"
              ></list-columns-filter>
              <div class="dataUC__header__filter-icon">
                <span class="icon is-small is-left">
                  <svg
                    class="svg-inline--fa fa-search"
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fas"
                    data-icon="search"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                  >
                    <path
                      class=""
                      fill="currentColor"
                      d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"
                    ></path>
                  </svg>
                </span>
                <el-input
                  v-model="inputSearch"
                  class="w-100 element_filter"
                  :placeholder="
                    $t('t_input_column_search_value', {
                      msg: nameColumnToFilter,
                    })
                  "
                  @change="filterData"
                />
              </div>
            </div>
          </div>
          <div class="dataUC__header-right">
            <div>
              <el-tooltip
                class="box-item"
                effect="light"
                :content="$t('t-download')"
                placement="top"
              >
                <button
                  v-if="isClickDownload"
                  @click="downloadCard"
                  type="button"
                  class="button-download"
                >
                  Excel
                  <span class="icon is-small"
                    ><svg
                      class="svg-inline--fa fa-file-excel"
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fas"
                      data-icon="file-excel"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 384 512"
                    >
                      <path
                        class=""
                        fill="currentColor"
                        d="M224 136V0H24C10.7 0 0 10.7 0 24v464c0 13.3 10.7 24 24 24h336c13.3 0 24-10.7 24-24V160H248c-13.2 0-24-10.8-24-24zm60.1 106.5L224 336l60.1 93.5c5.1 8-.6 18.5-10.1 18.5h-34.9c-4.4 0-8.5-2.4-10.6-6.3C208.9 405.5 192 373 192 373c-6.4 14.8-10 20-36.6 68.8-2.1 3.9-6.1 6.3-10.5 6.3H110c-9.5 0-15.2-10.5-10.1-18.5l60.3-93.5-60.3-93.5c-5.2-8 .6-18.5 10.1-18.5h34.8c4.4 0 8.5 2.4 10.6 6.3 26.1 48.8 20 33.6 36.6 68.5 0 0 6.1-11.7 36.6-68.5 2.1-3.9 6.2-6.3 10.6-6.3H274c9.5-.1 15.2 10.4 10.1 18.4zM384 121.9v6.1H256V0h6.1c6.4 0 12.5 2.5 17 7l97.9 98c4.5 4.5 7 10.6 7 16.9z"
                      ></path>
                    </svg>
                  </span>
                </button>
              </el-tooltip>
              <el-button
                v-if="isBtnLoading"
                type="button"
                :loading="loadingBtn"
              >
                {{ $t("t-loading-get-data") }}...
              </el-button>
            </div>
          </div>
        </div>
        <div class="dataUC__body">
          <table-screen-component
            :item="dataCarditem"
            :itemData="dataTable.result"
            :isLoading="loadingData"
            :configTable="dataCarditem.table?.format"
            :configPaging="dataCarditem.table?.isPaging"
            :listColumnsStatus="listColumnsStatus"
            :tableRules="tableRules"
            @getDataTableWhenSort="fn_tableSortChange"
          />
        </div>
        <div class="dataUC__footer" v-if="dataTable.data.length > 0">
          <div class="dataUC__footer-total" v-if="dataCarditem.table.isPaging">
            {{
              $t("t_pagitation_usecase", {
                from: tableRules.offset + 1,
                to: tableRules.offset + tableRules.limit,
                total: dataTable.total,
              })
            }}
          </div>
          <div class="dataUC__footer-total" v-else>
            {{
              $t("t_number_record", { number: dataTable.result.data.length })
            }}
          </div>
          <div
            class="dataUC__footer-pagination"
            v-if="dataCarditem.table.isPaging"
          >
            <el-pagination
              small
              background
              layout="pager"
              v-model:currentPage="tableRules.page"
              v-model:page-size="tableRules.limit"
              :page-sizes="tableRules.lengthMenu"
              :total="dataTable.total"
              @size-change="fn_tableSizeChange"
              @current-change="fn_tableCurentChange"
            />
            <button
              type="button"
              class="dataUC__footer-pagination-previous"
              @click="fn_tablePrevClick()"
              :disabled="isDisabledPagePrev || dataTable.totalPages == 1"
            >
              Previous
            </button>
            <button
              type="button"
              class="dataUC__footer-pagination-next"
              @click="fn_tableNextClick()"
              :disabled="isDisabledPageNext || dataTable.totalPages == 1"
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script setup>
import { ref, onMounted, reactive, watch } from "vue";
import Layout from "../../layouts/main.vue";
import mushroom from "cem-probe-api";
import { datePicker } from "../../stores/datePicker";
import MethodService from "../../service/MethodService";
import DataForm from "./dataTableScreen";
import { useRouter, useRoute } from "vue-router";
import ListPagination from "../../components/ListPagination.vue";
import ListColumnsName from "../../components/ListColumnsName.vue";
import ListColumnsFilter from "../../components/ListColumnsFilter";
import TableScreenComponent from "../../components/card-items/TableScreenComponent.vue";
import toastr from "toastr";
import { timePicker } from "../../stores/timePicker";
import { abortAPI } from "@/stores/abortAPI";
import { useI18n } from "vue-i18n";
const { t } = useI18n();
const loadingData = ref(false);
const loadingBtn = ref(false);
const storeDatePicker = datePicker();
const tableRules = reactive(MethodService.copyObject(DataForm.tableRules));
const router = useRouter();
const route = useRoute();
const showMenu = ref(false);
const dataTable = reactive({
  data: [],
  result: [],
  columnsOptionHide: [],
  selectColumnsToFilter: [],
  total: 0,
  totalPages: 0,
});
const idCardItem = ref();
const dataCarditem = ref({});
const listColHide = ref([]);
const countCloseListColumnsName = ref(0);
const countCloseListFilter = ref(0);
const countCloseWarningData = ref(0);
const nameFilterURL = ref("");
const nameColumnToFilter = ref("");
const valueColumnToFilter = ref("");
const typeColumnToFilter = ref("datepicker");
const inputSearch = ref("");
const keyListColumnsFilter = ref(0);
const isClickDownload = ref(true);
const isBtnLoading = ref(false);
const idRequestDownload = ref("");
const isDisabledPageNext = ref(false);
const isDisabledPagePrev = ref(true);
const listColumnsStatus = ref([]);
const storeTimePicker = timePicker();
const storeAbortAPI = abortAPI();
const abortController = new AbortController();

const getDataSource = async (card_item_id) => {
  loadingData.value = true;
  let bodyData = {
    card_item_id: card_item_id,
    probe_id: localStorage.pid,
    filters: {},
    extra: {
      customer: localStorage.customer ? localStorage.customer : "",
    },
    order: tableRules.sort,
    offset: tableRules.offset,
    start_date: storeDatePicker.showDatePicker[0],
    stop_date: storeDatePicker.showDatePicker[1],
  };

  if (dataCarditem.value.table?.isPaging) {
    bodyData.limit = tableRules.limit;
  }

  if (tableRules.filterKey.length > 0 && tableRules.filterValue.length > 0)
    bodyData.filters[tableRules.filterKey] = tableRules.filterValue.trim();

  const urlParams = window.location.search.slice(1);
  const searchParams = new URLSearchParams(urlParams);
  if (searchParams.get("customer")?.length > 0) delete bodyData.extra.customer;
  for (const [key, value] of searchParams) {
    if (
      key !== "start_date" &&
      key !== "stop_date" &&
      key !== "serviceID" &&
      key !== "$colfilter" &&
      key !== "$sort" &&
      key !== "$limit" &&
      key !== "$offset"
    )
      bodyData.extra[key] = value;
  }

  const time =
    router.name === "DichVuTheoKhungGio"
      ? storeTimePicker.showTimePicker
      : null;
  if (time) {
    bodyData.extra.start_hour = time[0];
    bodyData.extra.end_hour = time[1];
  }

  let sortURL = "";
  let filterURL = "";
  if (tableRules.filterKey.length > 0 && tableRules.filterValue.length > 0)
    filterURL = tableRules.filterKey + ":" + tableRules.filterValue.trim();
  for (const [key, value] of Object.entries(tableRules.sort)) {
    value == 1 ? (sortURL = key) : (sortURL = "-" + key);
  }

  const search = location.search.substring(1);

  let queryForPush = Object.fromEntries(new URLSearchParams(search));
  queryForPush.start_date = MethodService.formatDateYYYYMMDD(
    storeDatePicker.showDatePicker[0]
  );
  queryForPush.stop_date = MethodService.formatDateYYYYMMDD(
    storeDatePicker.showDatePicker[1]
  );
  queryForPush.$sort = sortURL;
  queryForPush.$limit = tableRules.limit;
  queryForPush.$offset = tableRules.offset;
  queryForPush.$colfilter = filterURL;

  router
    .replace({
      name: "TableScreen",
      query: queryForPush,
    })
    .catch(() => {});

  try {
    const response = await mushroom.probe_data.getDatasourceAsync(
      {
        body: bodyData,
        settings: {
          abortController,
        },
      },
      { cacheAge: 1 }
    );
    console.log("Kết quả trả về, response: %o", response);
    dataTable.data = [];
    dataTable.result = response.result;
    dataTable.total = response.result.total;
    dataTable.totalPages = Math.ceil(response.result.total / tableRules.limit);

    response.result.data.forEach((item) => {
      let obj = {};
      item.forEach((item1, index1) => {
        if (
          !response.result.columns[index1] ||
          !response.result.columns[index1].code
        )
          return;
        obj[response.result.columns[index1].code] = item1;
      });
      dataTable.data.push(obj);
    });
  } catch (error) {
    console.log("error.code: ", error?.detail);
    dataTable.result = {
      columns: [],
      data: [],
    };
    if (error?.detail?.includes("Invalid")) {
      toastr.warning(t("toastr_wrong_input_search"));
    } else {
      MethodService.showError(error.code);
    }
  } finally {
    loadingData.value = false;
  }
};

const getTableScreenById = async (id) => {
  try {
    const response = await mushroom.table_screen.findByIdAsync({
      id: id, // required
    });
    console.log("Thông tin table_screen trả về: %o", response);
    localStorage.setItem("tableScreenName", response.result.name);
  } catch (e) {
    console.error("Có lỗi: %o", e);
  }
};

const getCard = async (id) => {
  try {
    const response = await mushroom.card.listAsync({
      filters: [`table_screen_id=${id}`],
    });
    console.log("Các card thỏa mãn: %o", response);
    if (response.result[0]?.id) getIdCardItem(response.result[0].id);
  } catch (e) {
    console.error("Có lỗi: %o", e);
  }
};

const getIdCardItem = async (id) => {
  try {
    const response = await mushroom.card_item.listAsync({
      filters: ["card_id=" + id],
    });
    dataCarditem.value = response.result[0];
    showMenu.value = response.result[0].table?.isPaging ?? false;

    idCardItem.value = response.result[0].id;
    dataTable.selectColumnsToFilter = [];
    dataTable.columnsOptionHide = [];
    listColumnsStatus.value = [];
    listColHide.value = [];
    response.result[0].table.format.forEach((item) => {
      if (item.filter.is_allowed)
        dataTable.selectColumnsToFilter.push({
          value: item?.code,
          label: item.title_name,
          type: item.filter?.input_type ? item.filter.input_type : "input",
        });
      dataTable.columnsOptionHide.push({
        value: item.field_name,
        label: item.title_name,
        field_name: item.field_name,
      });

      if (item.visible && !item?.defaultHide)
        listColumnsStatus.value.push(item.field_name);
      if (item?.defaultHide) listColHide.value.push(item?.code);
    });
    if (response.result[0].id) getDataSource(response.result[0].id);
  } catch (e) {
    MethodService.showError(e.code);
    tableRules.sort;
  }
};

const showColumn = (nameColumn) => {
  listColumnsStatus.value.push(nameColumn);
};

const hideColumn = (nameColumn) => {
  listColumnsStatus.value = listColumnsStatus.value.filter(
    (item) => item !== nameColumn
  );
};

const filterData = async () => {
  const keySearch =
    valueColumnToFilter.value ?? dataTable.selectColumnsToFilter[0].value;
  tableRules.filterKey = keySearch;
  tableRules.offset = 0;
  tableRules.page = 1;

  tableRules.filterValue = inputSearch.value;
  await getDataSource(idCardItem.value);
};

const downloadCard = async () => {
  loadingBtn.value = true;
  isClickDownload.value = false;
  isBtnLoading.value = true;
  const search = location.search.substring(1);
  const data = search
    ? JSON.parse(
        '{"' + search.replace(/&/g, '","').replace(/=/g, '":"') + '"}',
        function (key, value) {
          return key === "" ? value : decodeURIComponent(value);
        }
      )
    : "";

  // 1. gửi lên để có id request
  const request_download_dashboard_object = {
    probe_id: localStorage.pid,
    type: "tablescreen",
    table_screen_id: route.params.id,
    start_date: storeDatePicker.showDatePicker[0],
    stop_date: storeDatePicker.showDatePicker[1],
    data: search ? JSON.stringify(data) : undefined,
  };
  try {
    const newId = await mushroom.request_download_dashboard.createAsync(
      request_download_dashboard_object
    );
    if (newId.result) {
      idRequestDownload.value = newId.result;
    }
    // 2. nếu request thành công, check trạng thái 5s 1 lần
    if (idRequestDownload.value.length > 0) {
      window.checkStatusDownloadProbe = setTimeout(checkStatus, 5000);
    } else clearTimeout(window.checkStatusDownloadProbe);
  } catch (e) {
    console.error("Có lỗi: %o", e);
    isClickDownload.value = true;
    isBtnLoading.value = false;
    window.toastr.error(e.detail);
  }
};

const checkStatus = async () => {
  let response;
  try {
    response = await mushroom.request_download_dashboard.findByIdAsync(
      {
        id: idRequestDownload.value,
      },
      { enabledCache: false }
    );
  } catch (e) {
    console.error("Có lỗi: %o", e);
    loadingBtn.value = false;
    isClickDownload.value = true;
    isBtnLoading.value = false;
    window.checkStatusDownloadProbe = setTimeout(checkStatus, 5000);
    return;
  }

  if (response.result.status == "error") {
    loadingBtn.value = false;
    isClickDownload.value = true;
    isBtnLoading.value = false;
    window.toastr.error(response.result.note);
    clearTimeout(window.checkStatusDownloadProbe);
    return;
  }

  if (response.result.status == "completed") {
    await MethodService.downloadFileDashboard(
      idRequestDownload.value,
      "dashboard_data.xlsx"
    );
    loadingBtn.value = false;
    isClickDownload.value = true;
    isBtnLoading.value = false;
    clearTimeout(window.checkStatusDownloadProbe);
    return;
  }
  window.checkStatusDownloadProbe = setTimeout(checkStatus, 5000);
};

const closeListFilter = () => {
  countCloseListFilter.value += 1;
};
const closeWarningData = () => {
  countCloseWarningData.value += 1;
};

const nameColumnToFilterPlacehoder = (nameColumn, valueColumn, typeColumn) => {
  nameColumnToFilter.value = nameColumn;
  valueColumnToFilter.value = valueColumn;
  typeColumnToFilter.value = typeColumn ? typeColumn : "datepicker";
  if (!tableRules.filterKey) inputSearch.value = "";

  const el = document.querySelector(".element_filter .el-input__inner");
  if (el) {
    el.focus();
  }
};

const closeListColumnsName = () => {
  countCloseListColumnsName.value += 1;
};

const fn_tableSortChange = async (column, order) => {
  dataTable.columnsOptionHide.forEach((item) => {
    if (item.value === column.prop) column.prop = item.field_name;
  });
  if (order) {
    tableRules.sort = order;
  } else {
    tableRules.sort = "";
  }
  await getDataSource(idCardItem.value);
};

const changePageFromListPagination = (pageSize) => {
  fn_tableSizeChange(pageSize);
};

// phân trang
const fn_tableSizeChange = (limit) => {
  tableRules.limit = limit;
  fn_tableChangeOffset(tableRules.page);
};
const fn_tableCurentChange = (page) => {
  const length = Math.ceil(dataTable.total / tableRules.limit);
  isDisabledPagePrev.value = page === 1 ? true : false;
  isDisabledPageNext.value = page === length ? true : false;
  fn_tableChangeOffset(page);
};
const fn_tableChangeOffset = async (page) => {
  tableRules.page = page;
  tableRules.offset = (tableRules.page - 1) * tableRules.limit;
  await getDataSource(idCardItem.value);
};

const fn_tablePrevClick = () => {
  isDisabledPageNext.value = false;
  const pagePrev = tableRules.page - 1;
  if (pagePrev == 1) {
    isDisabledPagePrev.value = true;
  }
  tableRules.offset = (pagePrev - 1) * tableRules.limit;
  fn_tableChangeOffset(pagePrev);
};
const fn_tableNextClick = () => {
  isDisabledPagePrev.value = false;
  const pageNext = tableRules.page + 1;
  if (pageNext == dataTable.totalPages) {
    isDisabledPageNext.value = true;
  }
  tableRules.offset = (pageNext - 1) * tableRules.limit;
  fn_tableChangeOffset(pageNext);
};

const getInfoURL = () => {
  if (route.query.$sort) {
    let strSort = route.query.$sort;
    let objSort = {};
    if (strSort.includes("-")) {
      let keySort = strSort.toString().replace("-", "");
      objSort[keySort] = -1;
      tableRules.sort = objSort;
    } else {
      objSort[strSort] = 1;
      tableRules.sort = objSort;
    }
    tableRules.defaultSort[0] = tableRules.sort;
    route.query.$sort.indexOf("-") !== -1
      ? (tableRules.defaultSort[1] = "descending")
      : (tableRules.defaultSort[1] = "ascending");
  }
  if (route.query.$colfilter) {
    nameFilterURL.value = route.query.$colfilter;
    let strFilterURL = route.query.$colfilter;
    let index = strFilterURL.toString().indexOf(":");
    tableRules.filterKey = strFilterURL.toString().slice(0, index);
    tableRules.filterValue = strFilterURL.toString().slice(index + 1);
    inputSearch.value = tableRules.filterValue;
  }
  if (route.query.$limit) {
    tableRules.limit = Number(route.query.$limit);
  }
  if (route.query.$offset) {
    tableRules.offset = Number(route.query.$offset);
  }
  tableRules.page = tableRules.offset / tableRules.limit + 1;
};

watch(
  () => storeDatePicker.showDatePicker,
  async () => {
    tableRules.offset = 0;
    getInfoURL();
    if (route.params.id) await getCard(route.params.id);
    keyListColumnsFilter.value += 1;
  }
);

watch(
  () => route.params.id,
  async () => {
    getInfoURL();
    if (route.params.id) {
      await getTableScreenById(route.params.id);
      await getCard(route.params.id);
    }
  }
);

onMounted(async () => {
  storeAbortAPI.setAbort(abortController);
  if (route.query?.start_date) {
    localStorage.setItem("dateSelect", [
      route.query.start_date,
      route.query.stop_date,
    ]);
    storeDatePicker.setDatePicker([
      route.query.start_date,
      route.query.stop_date,
    ]);
  }
  getInfoURL();
  if (route.params.id) {
    await getTableScreenById(route.params.id);
    await getCard(route.params.id);
  }
  keyListColumnsFilter.value += 1;
});
</script>
<link type="scss" src="../usecase/usecase.scss" />
